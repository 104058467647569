<template>
  <v-card>
    <v-card-title>
      <div class='title font-weight-medium'>Latest Report</div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container grid-list-xs fluid>
        <v-layout row wrap>
          <v-flex xs12>
            <div class='title text-xs-center'>
              {{ item.RTitle }}
            </div>
            <br />
            <hr />
            <br />
          </v-flex>
          <v-flex xs3>
            <div class='body-2'>
              Report Date:
            </div>
          </v-flex>
          <v-flex xs3>
            <div class='body-2 font-weight-regular'>
              {{ item.SampleDate | FormatDateISO }}
            </div>
          </v-flex>
          <v-flex xs3>
            <div class='body-2'>
              Lab In-charge:
            </div>
          </v-flex>
          <v-flex xs3>
            <div class='body-2 font-weight-regular'>
              {{ item.LabIn }}
            </div>
          </v-flex>
          <v-flex xs12>
            <br />
            <RepDis :allergens='item.RValues'></RepDis>
            <br />
          </v-flex>
          <v-flex xs12>
            <div class='body-2'>
              Remarks:
            </div>
            <div class='body-2 font-weight-regular'>
              {{ item.Remarks }}
            </div>
            <br />
            <br />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import RepDis from './RepDis';
export default {
  components: { RepDis },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          SampleDate: '',
          LabIn: '',
          RTitle: '',
          RValues: [],
          Remarks: ''
        };
      }
    }
  }
};
</script>

<style scoped></style>
