<template>
  <div>
    <table style='width: 85%;margin-left: 5%;margin-right: 5%'>
      <tr>
        <td style='width: 50%;text-align: start;'>
          Patient Name: {{ patientName }}
        </td>
        <td style='width: 50%;text-align: end'>
          Page: 1
        </td>
      </tr>
    </table>
    <br />
    <table
      style='width: 85%;margin-left: 5%;margin-right: 5%'
      class='ige_table'
    >
      <tbody>
        <tr class='ige_tr'>
          <th class='ige_th' style='width: 10%'>Code</th>
          <th class='ige_th' style='width: 40%'>Allergens</th>
          <th class='ige_th' style='width: 30%'>Local Name</th>
          <th class='ige_th' style='width: 10%'>SAU/ml</th>
          <th class='ige_th' style='width: 10%'>Class</th>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>VEGETABLES</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(2, 8, 0, 5, 4, 4)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>SPICES</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(5, 8, 4, 6, 8, 5)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>PULSES</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(0, 3, 6, 7, 3, 6)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <table style='width: 85%;margin-left: 5%;margin-right: 5%'>
      <tr>
        <td style='width: 50%;text-align: start;'>
          Patient Name: {{ patientName }}
        </td>
        <td style='width: 50%;text-align: end'>
          Page: 2
        </td>
      </tr>
    </table>
    <br />
    <table
      style='width: 85%;margin-left: 5%;margin-right: 5%'
      class='ige_table'
    >
      <tbody>
        <tr class='ige_tr'>
          <th class='ige_th' style='width: 10%'>Code</th>
          <th class='ige_th' style='width: 40%'>Allergens</th>
          <th class='ige_th' style='width: 30%'>Local Name</th>
          <th class='ige_th' style='width: 10%'>SAU/ml</th>
          <th class='ige_th' style='width: 10%'>Class</th>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(3, 8, 6, 7, 8, 6)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>HOT BEVERAGES</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(0, 8, 7, 8, 1, 7)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>BEANS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(2, 8, 7, 9, 2, 8)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>FRUITS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(3, 8, 8, 12, 0, 11)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>CEREALS & MILLETS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(1, 8, 11, 12, 7, 11)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>DRY FRUITS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(0, 4, 12, 13, 4, 12)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
      </tbody>
    </table>
    <table style='width: 85%;margin-left: 5%;margin-right: 5%'>
      <tr>
        <td style='width: 50%;text-align: start;'>
          Patient Name: {{ patientName }}
        </td>
        <td style='width: 50%;text-align: end'>
          Page: 3
        </td>
      </tr>
    </table>
    <br />
    <table
      style='width: 85%;margin-left: 5%;margin-right: 5%'
      class='ige_table'
    >
      <tbody>
      <tr class='ige_tr'>
        <th class='ige_th' style='width: 10%'>Code</th>
        <th class='ige_th' style='width: 40%'>Allergens</th>
        <th class='ige_th' style='width: 30%'>Local Name</th>
        <th class='ige_th' style='width: 10%'>SAU/ml</th>
        <th class='ige_th' style='width: 10%'>Class</th>
      </tr>
      <tr class='ige_tr'>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
<!--        <tr-->
<!--          class='ige_tr'-->
<!--          v-for='i in getAllergyDetails(4, 8, 9, 11, 8, 10)'-->
<!--          :key='i.code'-->
<!--        >-->
<!--          <td class='ige_text'>{{ i.code }}</td>-->
<!--          <td class='ige_text'>{{ i.allergen }}</td>-->
<!--          <td class='ige_text'>{{ i.local }}</td>-->
<!--          <td class='ige_text'>{{ i.sau }}</td>-->
<!--          <td class='ige_text ige_bold'>{{ i.cl }}</td>-->
<!--        </tr>-->
<!--        <tr class='ige_tr'>-->
<!--          <td></td>-->
<!--          <td class='ige_text ige_bold'>DAIRY PRODUCTS</td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--        </tr>-->
<!--        <tr-->
<!--          class='ige_tr'-->
<!--          v-for='i in getAllergyDetails(1, 8, 10, 12, 1, 11)'-->
<!--          :key='i.code'-->
<!--        >-->
<!--          <td class='ige_text'>{{ i.code }}</td>-->
<!--          <td class='ige_text'>{{ i.allergen }}</td>-->
<!--          <td class='ige_text'>{{ i.local }}</td>-->
<!--          <td class='ige_text'>{{ i.sau }}</td>-->
<!--          <td class='ige_text ige_bold'>{{ i.cl }}</td>-->
<!--        </tr>-->
<!--        <tr class='ige_tr'>-->
<!--          <td></td>-->
<!--          <td class='ige_text ige_bold'>SEEDS</td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--        </tr>-->
<!--        <tr-->
<!--          class='ige_tr'-->
<!--          v-for='i in getAllergyDetails(2, 8, 11, 12, 4, 11)'-->
<!--          :key='i.code'-->
<!--        >-->
<!--          <td class='ige_text'>{{ i.code }}</td>-->
<!--          <td class='ige_text'>{{ i.allergen }}</td>-->
<!--          <td class='ige_text'>{{ i.local }}</td>-->
<!--          <td class='ige_text'>{{ i.sau }}</td>-->
<!--          <td class='ige_text ige_bold'>{{ i.cl }}</td>-->
<!--        </tr>-->
<!--        <tr class='ige_tr'>-->
<!--          <td></td>-->
<!--          <td class='ige_text ige_bold'>PIZZA MIX</td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--        </tr>-->
<!--        <tr-->
<!--          class='ige_tr'-->
<!--          v-for='i in getAllergyDetails(5, 8, 11, 12, 7, 11)'-->
<!--          :key='i.code'-->
<!--        >-->
<!--          <td class='ige_text'>{{ i.code }}</td>-->
<!--          <td class='ige_text'>{{ i.allergen }}</td>-->
<!--          <td class='ige_text'>{{ i.local }}</td>-->
<!--          <td class='ige_text'>{{ i.sau }}</td>-->
<!--          <td class='ige_text ige_bold'>{{ i.cl }}</td>-->
<!--        </tr>-->
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>DRY FRUITS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <!--      (0, 8, 9, 11, 0, 10)-->
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(4, 8, 12, 14, 0, 13)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>DAIRY PRODUCTS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(1, 8, 13, 14, 1, 14)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>SEEDS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(2, 8, 14, 15, 4, 14)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>PIZZA MIX</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(5, 8, 14, 15, 7, 14)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { vegAllergyMixin } from '../../api/vegAllergyMixin';
import { vegAllergyLocalMixin } from '../../api/vegAllergyLocalMixin';
export default {
  mixins: [vegAllergyMixin, vegAllergyLocalMixin],
  props: {
    patientName: {
      type: String,
      default: ''
    },
    testValues: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allergyCodes: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    getAllergyDetails(
      rowStart,
      rowEnd,
      colStart,
      colEnd,
      breakRowCount,
      breakColCount
    ) {
      if (this.testValues.length < 1) return [];
      const pollens = [];
      try {
        for (let j = colStart; j < colEnd; j++) {
          for (let i = rowStart; i < rowEnd; i++) {
            try {
              const val = {
                code: this.allergyCodes[i] + (j + 1),
                allergen: this.vegAllergens[j][i],
                local: this.vegAllergyLocals[j][i],
                sau: 0,
                cl: ''
              };
              if (this.testValues[i][j]) {
                const sau = this.getSau(
                  this.testValues[0][0],
                  this.testValues[i][j]
                );
                val.sau = sau.sau;
                val.cl = sau.cl;
              }
              pollens.push(val);
              if (i === breakRowCount && j === breakColCount) break;
            } catch {
              console.log('Error at i:' + i + ',j:' + j);
            }
          }
          rowStart = 0;
        }
      } catch {
        console.log('Error at i:' + rowStart + ',j:' + colStart);
      }
      return pollens;
    },
    getSau(control, value) {
      const sau = (value / control).toFixed(2);
      let cl = '';
      if (sau >= 3 && sau < 3.5) cl = '3';
      else if (sau >= 3.5 && sau < 4.5) cl = '4';
      else if (sau >= 4.5 && sau < 5.5) cl = '5';
      else if (sau >= 5.5) cl = '6';
      return {
        sau: sau,
        cl: cl
      };
    }
  }
};
</script>

<style scoped></style>
