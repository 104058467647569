<template>
  <div>
    <table style='overflow: auto;height: 200px' class='ige_table'>
      <tr>
        <th v-for='index in items[0].length' :key='index' class='ige_header'>
          {{ index }}
        </th>
      </tr>
      <tr v-for='(row, index) in items' :key='index'>
        <td v-for='(item, index1) in row' :key='index1' class='ige_text'>
          {{ item }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
    };
  }
};
</script>

<style scoped>
th {
  width: 5%;
  text-align: center;
  border: 1px solid #838383;
}
td {
  text-align: center;
  border: 1px solid #838383;
}
tr {
  height: 30px;
}
.ige_table {
  border-collapse: collapse;
  font-family: arial, sans-serif;
  width: 100%;
}

.ige_header {
  font-size: medium;
  font-weight: bold;
}

.ige_text {
  font-size: medium;
  font-weight: normal;
}
</style>
