<template>
  <v-container fluid grid-list-xl>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Patient Specific IgE Test Details
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <v-flex xs12 sm3 lg3>
            <v-btn color='primary' v-show='allowAdd' @click='dlgCreate = true'>
              <v-icon class='iconText'>fa fa-plus</v-icon>
              New Elisa Test
            </v-btn>
          </v-flex>
          <v-flex xs12 sm3 lg3>
            <v-btn color='primary' v-show='allowAdd' @click='dlgScreening = true'>
              <v-icon class='iconText'>fa fa-plus</v-icon>
              New Screening Test
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <div class='filterStyle'>
            <v-text-field label='Patient Id:' v-model='patientId'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <v-flex>
            <v-btn :disabled='loading' @click='searchPatient'>
              <v-icon class='iconText'>fa fa-search</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <v-flex xs6 v-show='patient.Id'>
            <PatientCard :patient='patient'></PatientCard>
          </v-flex>
          <v-flex xs6 v-show='exam.Id'>
            <ExamSummary :exam='exam'></ExamSummary>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 lg12 v-show='patient.Id'>
        <SmResDis :item='items[0]' />
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <div>
          <LabDisplay
            :items='items'
            :loading='loading'
            @view='viewDlg'
          ></LabDisplay>
          <LabCreateDlg
            :pid='patient.Id'
            :dlg-lab='dlgCreate'
            @close='closeDlg'
            @add='addNewItem'
          ></LabCreateDlg>
          <LabScreeningDlg
            :pid='patient.Id'
            :dlg-lab='dlgScreening'
            @close='closeDlg'
            @add='addNewItem'
          ></LabScreeningDlg>
          <ReportDisDlg
            :dlg-show='dlgView'
            :item='selectedItem'
            :patient='patient'
            @close='closeDlg'
          ></ReportDisDlg>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ReportDisDlg from '../../components/lab/RepDisDlg';
import LabCreateDlg from '../../components/lab/LabReportCreateDlg';
import LabScreeningDlg from '../../components/lab/LabReportScreeningDlg';
import LabDisplay from '../../components/lab/LabDisplay';
import PatientCard from '../../components/patients/PatientCard';
import ExamSummary from '../../components/exam/ExamSummary';
import SmResDis from '../../components/lab/SmRepDis';
import WebH from '../../api/web';
export default {
  components: {
    PatientCard,
    ExamSummary,
    LabDisplay,
    LabCreateDlg,
    LabScreeningDlg,
    ReportDisDlg,
    SmResDis
  },
  created() {
    this.allowAdd = this.$store.getters['UserStore/permissions'].includes(
      'l.a'
    );
    this.patientId = this.$store.getters['SettingsStore/patientId'];
    this.searchPatient();
  },
  data() {
    return {
      allowAdd: false,
      loading: false,
      items: [],
      exam: {},
      patientId: '',
      patient: {},
      dlgCreate: false,
      dlgScreening: false,
      dlgView: false,
      selectedItem: null
    };
  },
  methods: {
    closeDlg() {
      this.selectedItem = null;
      this.dlgCreate = false;
      this.dlgScreening = false;
      this.dlgView = false;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgView = true;
    },
    addNewItem(item) {
      this.items.push(item);
      this.$notify({
        type: 'info',
        text: 'Successfully added new Specific IgE report '
      });
    },
    async searchPatient() {
      if (this.patientId === '') {
        return;
      }
      this.$store.commit('SettingsStore/setPatientId', this.patientId);
      this.loading = true;
      this.patient = {};
      this.exam = {};
      this.items = [];
      let url = '/patients/search?&name=&pid=' + this.patientId + '&mobile=';
      const res = await WebH.Get(url, 'patients');
      if (res.Success) {
        if (res.Data.length > 0) {
          this.patient = res.Data[0];
          await this.getRelatedData();
        } else {
          await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        }
      }
      this.loading = false;
    },
    async getRelatedData() {
      this.getExam();
      this.getLabReports();
    },
    async getExam() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.exam = {};
      let url = '/exams/' + this.patient.Id;
      const res = await WebH.Get(url, 'examination');
      if (res.Success) {
        this.exam = res.Data;
      }
      this.loading = false;
    },
    async getLabReports() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.items = [];
      let url = '/ige/' + this.patient.Id + '/all';
      const res = await WebH.Get(url, 'lab reports');
      if (res.Success) {
        this.items = res.Data;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
