<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='800px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>View EIA values</div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <CSVReport :items='items' />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click='closeDlg'>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CSVReport from './CSVDisplay';
export default {
  components: {
    CSVReport
  },
  props: {
    dlgShow: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    closeDlg() {
      this.$emit('closeCSV');
    }
  }
};
</script>

<style scoped></style>
