export const nVegAllergyMixin = {
  data() {
    return {
      nVegAllergens: [
        ["", "", "Anchovies", "Beef", "Chicken", "Clam", "Crab", "Cray fish"],
        [
          "Egg white",
          "Egg yolk",
          "Goat",
          "India mackerel",
          "Leiognathus bimdas",
          "Lobster",
          "Pork",
          "Prawn"
        ],
        [
          "Ray",
          "Salmon",
          "Saradine",
          "Shark",
          "Sheep",
          "Shrimp",
          "Soles",
          "Thryssa mystax"
        ]
      ]
    };
  }
};
