<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='850px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>View Report</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <div id='printSummary'>
            <v-row no-gutters>
              <v-col sm="2" md="2">
                <img
                    src='../../../public/img/bac_logo.svg'
                    class='logo_style'
                    alt='BAC'
                />

              </v-col>
              <v-col sm="8" md="8">
                <div class='pHeadline color_black'>
                  BAC DIAGNOSTICS
                </div>
              </v-col>
              <v-col sm="2" md="2"></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="12" md="12">
                <p class="para">
                  (A unit of Bengaluru Allergy Centr, Registration No: BLU01793AACD)
                  <br>
                  169/170 31st Cross Road,11th Main Road, 4th T Block East
                  <br>
                  Jayanagar, Bengaluru, Karnataka 560011
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="12" md="12">
                <p class="text_bold color_black">
                  Mrs. Sudha Nagendra K, CEO
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="12" md="12">
                <div class='pHeadline text-sm-center color_black'>
                  {{ item.RTitle }}
                </div>
                <br />
                <hr />
                <br />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="2">
                <div class='body-2 color_black'>
                  Patient Name:
                </div>
              </v-col>
              <v-col sm="4">
                <div class='body-2 font-weight-regular color_black'>
                  {{ patient.Name }}
                </div>
              </v-col>
              <v-col sm="2">
                <div class='body-2 color_black'>
                  Patient ID:
                </div>
              </v-col>
              <v-col sm="4">
                <div class='body-2 font-weight-regular color_black'>
                  {{ patient.PatientId }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="2">
                <div class='body-2 color_black'>
                  Report Date:
                </div>
              </v-col>
              <v-col sm="4">
                <div class='body-2 font-weight-regular color_black'>
                  {{ item.SampleDate | FormatDateISO }}
                </div>
              </v-col>
<!--              <v-col sm="2">-->
<!--                <div class='body-2'>-->
<!--                  Lab In-charge:-->
<!--                </div>-->
<!--              </v-col>-->
<!--              <v-col sm="4">-->
<!--                <div class='body-2 font-weight-regular'>-->
<!--                  {{ item.LabIn }}-->
<!--                </div>-->
<!--              </v-col>-->
            </v-row>
            <v-row no-gutters>
              <v-col sm="12">
                <RepDis style="margin-top: 10px;margin-bottom: 20px;" :allergens='item.RValues'></RepDis>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="12">
                <div class='body-2 color_black'>
                  Remarks:
                </div>
                <div class='body-2 font-weight-regular color_black'>
                  Consult Doctor for further details
<!--                  {{ item.Remarks }}-->
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="9"></v-col>
              <v-col sm="3">
                <p class="body-2 text-sm-center color_black">
<!--                  {{ userName }}-->
<!--                  <br>-->
                  BAC Diagnostics
                  <br>
                  Research Associate
                </p>
                <!--                <div class='body-2' style='text-align: right'>-->
                <!--                  {{ userName }}-->
                <!--                </div>-->
                <!--                <div class='body-2' style='text-align: '>-->
                <!--                  Research Associate-->
                <!--                </div>-->
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="12">
                <div class='body-2 color_black'>
                  * Specific Arbitrary Units (SAU)
                </div>
                <div class='body-2 color_black'>
                  Note: The report generated is related to the sample tested.
                  The investigation has limitation with specificity and
                  sensitivity of the test. The report should co-relate with
                  clinical findings.
                  <br />
                  <br />
                </div>
                <br />
                <br />
              </v-col>
            </v-row>
          </div>
          <div id='printDetailedReport'>
            <v-row no-gutters v-if='detailedReport'>
              <!--              header-->
              <v-col xs="2" md="2" lg="2">
                <div style='vertical-align: center;text-align: left'>
                  <img
                    src='../../../public/img/bac_logo.svg'
                    style='width: 70px;height: 30px'
                    alt='BAC'
                  />
                </div>
              </v-col>
              <v-col xs="8" md="8" lg="8">
                <div class='pHeadline'>
                  {{ item.RTitle }}
                </div>
              </v-col>
              <v-col xs="2" md="2" lg="2">
                <div style='vertical-align: center;text-align: right'>
                  {{ item.SampleDate | FormatDateISO }}
                </div>
              </v-col>

              <!-- Body -->
              <v-col xs="12" md="12" lg="12">
                <div v-if='reportType === 1'>
                  <AirAllergenProfile
                          :patient-name='patient.Name'
                    :allergyCodes='charCodes'
                    :test-values='item.TestValues'
                  />
                </div>
                <div v-if='reportType === 2'>
                  <FoodAllergenProfile
                    :patient-name='patient.Name'
                    :allergyCodes='charCodes'
                    :test-values='item.TestValues'
                  />
                </div>
                <div v-if='reportType === 3'>
                  <NonVegAllergenProfile
                          :patient-name='patient.Name'
                    :allergyCodes='charCodes'
                    :test-values='item.TestValues'
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click='printSummary' color='primary'>Print Summary</v-btn>
        <v-btn text @click='printDetailedReport' color='primary'
          >Print Detailed Report</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text @click='closeDlg'>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RepDis from './RepDis';
import AirAllergenProfile from './AirAllergenProfie';
import NonVegAllergenProfile from './NonVegAllergenProfile';
import FoodAllergenProfile from './FoodAllergenProfile';
import Print from '/public/print';

export default {
  components: {
    RepDis,
    AirAllergenProfile,
    NonVegAllergenProfile,
    FoodAllergenProfile
  },
  props: {
    dlgShow: { type: Boolean, default: false },
    patient: { type: Object },
    item: {
      type: Object,
      default: () => {
        return {
          SampleDate: '',
          LabIn: '',
          RTitle: '',
          RValues: [],
          Remarks: ''
        };
      }
    }
  },
  data() {
    return {
      charCodes: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
    };
  },
  computed: {
    userName: {
      get() {
        return 'Lakshmi K.';
        // const name = this.$store.getters['UserStore/user'];
        // console.log(name);
        // return this.$store.getters['UserStore/user'].Name;
      }
    },
    reportType: {
      get() {
        if (this.item.RTitle.includes('Food')) return 2;
        else if (this.item.RTitle.includes('Airborne')) return 1;
        else if (this.item.RTitle.includes('Non-Veg')) return 3;
        else return false;
      }
    },
    detailedReport: {
      get() {
        if (this.item.RTitle.includes('Food')) return true;
        else if (this.item.RTitle.includes('Airborne')) return true;
        else if (this.item.RTitle.includes('Non-Veg')) return true;
        else return false;
      }
    }
    // reportGenerated: {
    //   get() {
    //     if (this.item.TestValues.length < 1) return [];
    //     const report = [];
    //     for (let i = 0; i < 8; i++) {
    //       for (let j = 0; j < this.codeEnd; j++) {
    //         const allergy = {
    //           code: this.charCodes[i]
    //         };
    //       }
    //     }
    //
    //     return report;
    //   }
    // }
  },
  methods: {
    getAllergen() {},
    closeDlg() {
      this.$emit('close');
    },
    async printSummary() {
      Print({
        printable: 'printSummary',
        type: 'html',
        css: ['/vuetify_latest.css', '/ph.css'],
        targetStyles: ['*']
      });
    },
    printDetailedReport() {
      Print({
        printable: 'printDetailedReport',
        type: 'html',
        css: ['/vuetify_latest.css', '/ph.css'],
        targetStyles: ['*']
      });
    }
  }
};
</script>

<style scoped></style>
