<template>
  <v-data-table
      class='elevation-1'
      :footer-props='footerProps'
      :headers='headers'
      :items='items'
      item-key='Id'
      :loading='loading'
      :sort-by.sync='pageSortBy'
      sort-desc.sync='true'
  >
    <template v-slot:item.SampleDate="{ item }">
      {{ item.SampleDate | FormatDateISO }}
    </template>
    <template v-slot:item.actions="{ item }">
      <tr>
        <td style='text-align: center'>
          <button class='linkBtn' type='button' @click='viewDlg(item)'>
            View
          </button>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['items', 'loading'],
  data() {
    return {
      footerProps: {itemsPerPageOptions: [25, 50]},
      pageSortBy: 'SampleDate',
      headers: [
        {
          text: 'Date',
          value: 'SampleDate',
          sortable: true,
          width: '15%'
        },
        {
          text: 'Report Type',
          value: 'RTitle',
          sortable: true,
          width: '60%'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '15%',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    viewDlg(item) {
      this.$emit('view', item);
    }
  }
};
</script>

<style scoped></style>
