export const vegAllergyLocalMixin = {
  data() {
    return {
      vegAllergyLocals: [
        [
          "",
          "",
          "Petha - Boodha Gumbalakai",
          "Tejpatha-Patrika",
          "Chukander-Beetroot",
          "Dhantu",
          "Karela-Hagalkai",
          "Lokki-Sorekai"
        ],
        [
          "Baingan-Badnekai",
          "Bhandh Gobi-Geddae Kosu",
          "Shimla Mirch-Dappamenasinakai",
          "Gajar-Carrot",
          "Phool Ghobi-Hookosu",
          "Naariyal-Tenginakai",
          "Dhaniya Patha-Kothambari Soppu",
          "Kakadi-Sothekai"
        ],
        [
          "Caddi Phatta-Karibevina Soppu",
          "Sowa/suva patta-Sabbasige Soppu",
          "Seeng-Nuggekai",
          "Methi-Menthva Soppu",
          "Ganth Gobi-Navilkosu",
          "Bindhi-Bendaekai",
          "kasmisaag-Halmoolangi Soppu",
          "Bhutta-Jola"
        ],
        [
          "Pudina-Mentha",
          "Anabae-Nayikodae",
          "Pyaz-Irulli",
          "Alu-Alugadde",
          "Lal Kaddu-Kumbalakai",
          "Mooli-Mulangi",
          "Turai-Hirekai",
          "Parwal-Padwalakai"
        ],
        [
          "Palak-Palak Soppu",
          "ShakkarKhandi- Sihigenasu",
          "Tendli-Thondekai",
          "Tomato-Tomato",
          "Shalgam- Navilu kosu",
          "Kaali Mirch-kaalu Menasu",
          "Mirchi-Menasinakai",
          "Ajinomoto"
        ],
        [
          "Dhaniya -Kotaambari Kalu",
          "Jeera-Jeerige",
          "",
          "Lazhun-Belluli",
          "Adrak- Shunti",
          "Kesari",
          "Thil-Yellu",
          "Haldi-Arishina"
        ],
        [
          "Kaala Channa-Kadale Bele",
          "Kabuli channa- Channa kalu",
          "Channa- Hurigadale",
          "Moong dal-Hesarubele",
          "Kulthi-Hurali kaalu",
          "Red lentils-Kempu Togari Bele",
          "Urad dal-Uddina bele",
          "Arhar-Toor dal–Togari bele"
        ],
        [
          "Coffee",
          "Chai-chaha",
          "Chowli/Lobia",
          "Seim ki Phalli-Chapradavare kaalu",
          "Gawhar-Gorikayi",
          "Papdi lilva-Val-Sem-Avarekalu",
          "Pharasi Beans-Hurlikai",
          "Muttur-Batani"
        ],
        [
          "Rajma-Tigalavari",
          "Bhatmas-Batma soyab",
          "Alasandi",
          "Seb-Sebu",
          "Khela-Pachbale, Yelchi Kela",
          "Cherry",
          "Chiku-Sapota",
          "Seethaphal-Sethaphala"
        ],
        [
          "Amla-Nallikai",
          "Angur-Drakshi",
          "Amruda-Sibe hannu",
          "Kathal-Halasina hannu",
          "Nimbu-Nimbehannu",
          "Lichi",
          "Aam-Maavina hannu",
          "Kharbhuja"
        ],
        [
          "Jaitoon-Zetoon",
          "Santra-Kittalehannu",
          "Papitha-Parangi hannu",
          "Anannas-Pineapple",
          "Anar-Dalimbrae",
          "Ganna- Kabbu",
          "Moosambi",
          "Emili-Hunasehannu"
        ],
        [
          "Tarbhooj-Kalangadi",
          "Mandua-Ragi",
          "Poha / Chiwda -Avalakki",
          "Bajra /Jola",
          "",
          "Jai / Tooke Goodhi",
          "Chawal-Akki",
          "Gehoom-Godi"
        ],
        [
          "Badam- Badami",
          "Khubani- Jaldaru hannu",
          "Khaju-Godambi",
          "Khajur-Kharjura",
          "Moong phalli-Nelagadale",
          "Pahadi Badam - Gujja",
          "Pista -Pista",
          "Khismis-Drakshi"
        ],
        [
          "Akhroot",
          "Khameer-Yeast",
          "Makkhan-Bennae",
          "",
          "Cheese",
          "Chocolate",
          "Dahi - Mosaru",
          "Doodh - Haalu"
        ],
        [
          "Paneer",
          "Thoop-Thuppa",
          "Saas beej / Agase beeja",
          "Sarso-rye-sasuve ",
          "Khus Khus-Gasgase",
          "Mirch ke gucche",
          "Ajmood-Achhu Moda",
          "Ajavaiyana ki patti"
        ]
      ]
    };
  }
};
