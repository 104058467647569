<template>
  <v-dialog v-model='dlgLab' scrollable persistent max-width='800px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          Add Specific IgE report
        </div>
      </v-card-title>
      <v-card-text>
        <v-menu
          ref='dtMenu'
          :close-on-content-click='false'
          lazy
          nudge-right='40'
          offset-y
          full-width
          max-width='290px'
          min-width='290px'
          v-model='dtMenu'
        >
          <template v-slot:activator='{ on }'>
            <v-text-field
              v-model='dlgModel.SampleDate'
              label='Select a date:'
              v-on='on'
              name='rDt'
              readonly
              v-validate='{ required: true }'
              :error='!!errors.has("rDt")'
              :error-messages='errors.first("rDt")'
            >
              <template slot='append'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model='dlgModel.SampleDate'
            no-title
            @input='dtMenu = false'
          />
        </v-menu>
        <v-container grid-list-xs fluid>
          <v-layout row wrap>
            <v-flex xs12>
              <div class='body-2'>Test type:</div>
              <v-radio-group v-model='testType' row>
                <v-radio
                  label='Airborne Allergens'
                  :value='1'
                  color='primary'
                />
                <v-radio label='Food Allergens' :value='2' color='primary' />
                <v-radio label='Non-Veg Allergens' :value='3' color='primary' />
              </v-radio-group>
            </v-flex>
            <v-flex xs12 v-show='testType === 3'>
              <div class='body-2'>Non-Veg Allergens Set:</div>
              <v-radio-group v-model='nvSet' row>
                <v-radio label='1-3' :value='1' color='primary' />
                <v-radio label='4-6' :value='2' color='primary' />
                <v-radio label='7-9' :value='3' color='primary' />
                <v-radio label='10-12' :value='4' color='primary' />
              </v-radio-group>
            </v-flex>
            <v-flex xs12 v-if='testType === 2'>
              <v-flex>
                <v-text-field
                  label='Select 1st 8 column CSV file'
                  v-model='csvFile'
                  @click='pickFile'
                >
                  <template slot='prepend'>
                    <v-icon class='filterIcon grey--text text--darken-1'
                      >fa fa-paperclip</v-icon
                    >
                  </template>
                </v-text-field>
                <input
                  type='file'
                  ref='csv'
                  style='display: none'
                  @change='openCsvFile'
                  accept='.csv'
                />
                <!--                <v-btn @click='showCSVDlg'>View</v-btn>-->
              </v-flex>
              <v-flex>
                <v-text-field
                  label='Select last 3 column CSV file'
                  v-model='csv1File'
                  @click='pick1File'
                >
                  <template slot='prepend'>
                    <v-icon class='filterIcon grey--text text--darken-1'
                      >fa fa-paperclip</v-icon
                    >
                  </template>
                </v-text-field>
                <input
                  type='file'
                  ref='csv1'
                  style='display: none'
                  @change='openCsv1File'
                  accept='.csv'
                />
                <!--                <v-btn @click='showCSVDlg'>View</v-btn>-->
              </v-flex>
            </v-flex>
            <v-flex xs10 v-else>
              <v-flex>
                <v-text-field
                  label='Select CSV file'
                  v-model='csvFile'
                  @click='pickFile'
                >
                  <template slot='prepend'>
                    <v-icon class='filterIcon grey--text text--darken-1'
                      >fa fa-paperclip</v-icon
                    >
                  </template>
                </v-text-field>
                <input
                  type='file'
                  ref='csv'
                  style='display: none'
                  @change='openCsvFile'
                  accept='.csv'
                />
              </v-flex>
            </v-flex>

            <!--            <v-flex xs2>-->
            <!--              <v-btn @click='showCSVDlg'>View</v-btn>-->
            <!--            </v-flex>-->
            <!--<v-flex xs12>-->
            <!--<v-btn>Paste EIA values</v-btn>-->
            <!--</v-flex>-->
            <v-flex xs12>
              <CSVDlg
                :items='allergenData'
                :dlg-show='dlgCsv'
                @closeCSV='closeCSVDlg'
              />
            </v-flex>

            <v-flex xs12>
              <v-btn color='primary' @click='generateReport'
                >Generate Report</v-btn
              >
            </v-flex>

            <v-flex xs12>
              <br />
              <RepDis :allergens='allergensReport' />
              <br />
            </v-flex>

            <v-flex xs12>
              <v-text-field label='Remarks:' v-model='dlgModel.Remarks' />
            </v-flex>

            <div v-show='submit.error'>
              <v-list two-line>
                <v-list-item>
                  <span class='red--text'>{{ submit.message }}</span>
                </v-list-item>
              </v-list>
            </div>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RepDis from './RepDis';
import CSVDlg from './CSVDisDlg';
import WebH from '../../api/web';
import { airAllergyMixin } from '../../api/airAlergyMixin';
import { vegAllergyMixin } from '../../api/vegAllergyMixin';
import { nVegAllergyMixin } from '../../api/nVegAllergyMixin';
export default {
  mixins: [airAllergyMixin, vegAllergyMixin, nVegAllergyMixin],
  components: {
    CSVDlg,
    RepDis
  },
  props: {
    pid: { type: Number, default: 0 },
    dlgLab: { type: Boolean, default: false }
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dtMenu: false,
      testType: 1,
      nvSet: 1,
      dlgCsv: false,
      dlgModel: {
        RTitle: '',
        RValues: [],
        TestValues: [],
        Remarks: '',
        SampleDate: null
      },
      csvFile: '',
      csv1File: '',
      table: [],
      report: []
    };
  },
  computed: {
    allergenData() {
      if (this.table.length < 1) return [];
      const colLength = this.testType === 1 ? 8 : this.testType === 2 ? 14 : 2;

      if (this.testType !== 3) {
        const arr = [];
        for (let i = 0; i < this.table.length; i++) {
          const row = this.table[i];
          const tarr = [];
          for (let j = 0; j <= colLength; j++) {
            tarr.push(row[j]);
          }
          arr.push(tarr);
        }
        return arr;
      } else {
        const colStart =
          this.nvSet === 1
            ? 0
            : this.nvSet === 2
            ? 3
            : this.nvSet === 3
            ? 6
            : 9;
        const arr = [];
        for (let i = 0; i < this.table.length; i++) {
          const row = this.table[i];
          const tarr = [];
          for (let j = colStart; j <= colLength + colStart; j++) {
            tarr.push(row[j]);
          }
          arr.push(tarr);
        }
        return arr;
      }
    },
    allergensReport() {
      if (this.report.length < 1) return [];
      return this.report;
    }
  },
  methods: {
    showCSVDlg() {
      if (this.table.length < 1) {
        return;
      }
      this.dlgCsv = true;
    },
    closeCSVDlg() {
      this.dlgCsv = false;
    },
    pickFile() {
      this.$refs.csv.click();
    },
    pick1File() {
      this.$refs.csv1.click();
    },
    openCsvFile: function(e) {
      this.submit.error = false;
      this.submit.message = '';
      this.table = [];
      this.report = [];
      const files = e.target.files;

      if (files[0] === undefined) {
        this.csvFile = '';
        return;
      }

      this.csvFile = files[0].name;
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const rows = reader.result.split('\n');
          for (let i = 2; i < rows.length; i++) {
            const cols = rows[i].split(',');
            if (cols.length > 1) {
              const temp = [];
              for (let j = 1; j < cols.length; j++) {
                temp.push(Number.parseFloat(cols[j].trim()));
              }
              this.table.push(temp);
            }
          }
        } catch {
          this.table = [];
          this.submit.error = true;
          this.submit.message =
            'Error when reading csv file\nPlease check whether the file is corrupted or values incorrect';
        }
      };

      reader.readAsText(files[0]);
    },
    openCsv1File: function(e) {
      this.submit.error = false;
      this.submit.message = '';

      const tabData = this.table;
      const files = e.target.files;

      if (files[0] === undefined) {
        this.csv1File = '';
        return;
      }

      this.csv1File = files[0].name;
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const rows = reader.result.split('\n');
          for (let i = 2; i < rows.length; i++) {
            const cols = rows[i].split(',');
            if (cols.length > 1) {
              //const temp = [];
              for (let j = 10; j < cols.length; j++) {
                tabData[i - 2].push(Number.parseFloat(cols[j].trim()));
                //temp.push(Number.parseFloat(cols[j].trim()));
              }
              //this.table.push(temp);
            }
          }
        } catch {
          this.table = [];
          this.submit.error = true;
          this.submit.message =
            'Error when reading csv file\nPlease check whether the file is corrupted or values incorrect';
        }
      };

      reader.readAsText(files[0]);
    },
    // pasteValues() {
    //   const clipText = window.clipboardData.getData('Text');
    //
    //   const rows = clipText.split(String.fromCharCode(13));
    //
    //   for (let i = 0; i < rows.length; i++) {
    //     this.table.push(rows[i].split(String.fromCharCode(9)));
    //   }
    // },

    generateReport() {
      if (this.table.length < 1) return;
      this.report = [];
      const posCntrl = this.allergenData[0][0];
      for (let i = 0; i < this.allergenData.length; i++) {
        const rows = this.allergenData[i];
        for (let j = 0; j < rows.length; j++) {
          if ((i === 0 || i === 1) && j === 0) continue;
          const value = +(rows[j] / posCntrl).toFixed(2);
          if (value >= 3) {
            //console.log('i:' + i + '\tj:' + j + '\tvalue:' + value);
            const rep = {
              Name: this.getAllergenName(i, j),
              Reading: value
            };
            this.report.push(rep);
          }
        }
      }
    },

    getAllergenName(row, column) {
      try {
        if (this.testType === 1) {
          return this.airAllergens[column][row];
        } else if (this.testType === 2) {
          return this.vegAllergens[column][row];
        } else if (this.testType === 3) {
          return this.nVegAllergens[column][row];
        }
      }
      catch{
        console.log('Error at Row: '+row+'Column: '+column);
      }
    },

    closeDlg() {
      this.csvFile = '';
      this.csv1File = '';
      this.table = [];
      this.report = [];
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dtMenu = false;
      this.dlgModel = {
        RTitle: '',
        RValues: [],
        TestValues: [],
        Remarks: '',
        SampleDate: null
      };
      this.$emit('close');
    },
    async saveDlg() {
      if (this.pid < 1) {
        await this.$notifyDlg.show('Invalid patient', 'Error', 'error');
        return;
      }
      this.dlgModel.PId = this.pid;
      this.dlgModel.RValues = this.allergensReport;
      this.dlgModel.TestValues = this.allergenData;
      if (this.testType === 1)
        this.dlgModel.RTitle =
          'Specific IgE Test for Airborne Allergens by EIA';
      else if (this.testType === 2)
        this.dlgModel.RTitle = 'Specific IgE Test for Food Allergens by EIA';
      else
        this.dlgModel.RTitle = 'Specific IgE Test for Non-Veg Allergens by EIA';
      this.api_create(this.dlgModel);
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/ige', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
