<template>
  <div>
    <br />
    <table style='width: 85%;margin-left: 5%;margin-right: 5%'>
      <tr>
        <td style='width: 50%;text-align: start;'>
          Patient Name: {{ patientName }}
        </td>
        <td style='width: 50%;text-align: end'>
          Page: 1
        </td>
      </tr>
    </table>
    <br />
    <table style='width: 85%;margin-left: 5%;margin-right: 5%' class='ige_table'>
      <tr class='ige_tr'>
        <th class='ige_th' style='width: 10%'>Code</th>
        <th class='ige_th' style='width: 40%'>Allergens</th>
        <th class='ige_th' style='width: 30%'>Local Name</th>
        <th class='ige_th' style='width: 10%'>SAU/ml</th>
        <th class='ige_th' style='width: 10%'>Class</th>
      </tr>
      <tr class='ige_tr'>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class='ige_tr'>
        <td></td>
        <td class='ige_text ige_bold'>NON VEG</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr
        class='ige_tr'
        v-for='i in getAllergyDetails(2, 8, 0, 3, 8, 2)'
        :key='i.code'
      >
        <td class='ige_text'>{{ i.code }}</td>
        <td class='ige_text'>{{ i.allergen }}</td>
        <td class='ige_text'>{{ i.local }}</td>
        <td class='ige_text'>{{ i.sau }}</td>
        <td class='ige_text ige_bold'>{{ i.cl }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { nVegAllergyMixin } from '../../api/nVegAllergyMixin';
import { nVegAllergyLocalMixin } from '../../api/nVegAllergyLocalMixin';
export default {
  mixins: [nVegAllergyMixin, nVegAllergyLocalMixin],
  props: {
    patientName: {
      type: String,
      default: ''
    },
    testValues: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allergyCodes: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    getAllergyDetails(
      rowStart,
      rowEnd,
      colStart,
      colEnd,
      breakRowCount,
      breakColCount
    ) {
      if (this.testValues.length < 1) return [];
      const pollens = [];
      for (let j = colStart; j < colEnd; j++) {
        for (let i = rowStart; i < rowEnd; i++) {
          const val = {
            code: this.allergyCodes[i] + (j + 1),
            allergen: this.nVegAllergens[j][i],
            local: this.nVegAllergyLocals[j][i],
            sau: 0,
            cl: ''
          };
          if (this.testValues[i][j]) {
            const sau = this.getSau(
              this.testValues[0][0],
              this.testValues[i][j]
            );
            val.sau = sau.sau;
            val.cl = sau.cl;
          }
          pollens.push(val);
          if (i === breakRowCount && j === breakColCount) break;
        }
        rowStart = 0;
      }
      return pollens;
    },
    getSau(control, value) {
      const sau = (value / control).toFixed(2);
      let cl = '';
      if (sau >= 3 && sau < 3.5) cl = '3';
      else if (sau >= 3.5 && sau < 4.5) cl = '4';
      else if (sau >= 4.5 && sau < 5.5) cl = '5';
      else if (sau >= 5.5) cl = '6';
      return {
        sau: sau,
        cl: cl
      };
    }
  }
};
</script>

<style scoped></style>
