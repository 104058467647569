export const airAllergyLocalMixin = {
  data() {
    return {
      airAllergyLocals: [
        ["", "", "Anjan", "Doobghas", "Silk grass", "Bajra", "Jowar", "Bansa"],
        [
          "Neelam",
          "Kante wali chauli",
          "Peeli kanteli",
          "Barna, Sita bani, Bano",
          "Sarson",
          "Ban bhantes",
          "Bathua",
          "Kharbathua"
        ],
        [
          "Mothaghas",
          "Hopbush, Vilayti-mehdi, Sinatha",
          "Spiderwisp / Tilavan",
          "Sun flower / Suryakanthi",
          "Carrot weed/ Congress grass",
          "Tamrashimbi/ Copper Pod",
          "Arand",
          "Bui / Lonia"
        ],
        [
          "Patera",
          "Bichha, Kutta, Chirchitta",
          "Makai",
          "Neem",
          "Papita",
          "Kassod tree",
          "Coconut, Naariyal",
          "Safeda"
        ],
        [
          "Chilbil",
          "",
          "Vilayathi kekra",
          "Pitrunjiva",
          "Pilu, Khanja",
          "Siras",
          "",
          ""
        ],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        [
          "",
          "",
          "",
          "",
          "Cheenti",
          "Thilchatta / Jirale",
          "Thilchatta / Jirale",
          "Madhu makkhi"
        ],
        ["Gharelu makkhi", "Machhar (Culex)", "", "", "", "", "", ""]
      ]
    };
  }
};
