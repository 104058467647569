export const nVegAllergyLocalMixin = {
  data() {
    return {
      nVegAllergyLocals: [
        [
          "",
          "",
          "Bindt-White bait",
          "Go maans-Dana",
          "Murghi-koli",
          "Tisario",
          "Kekda-Aedi",
          "Narangi"
        ],
        [
          "Anda saphed-Motte bili",
          "Anda peela-Motte haldi",
          "Bakra- Meke",
          "Bangude - Bony fish",
          "Silver belly-Kuruchi",
          "Jeenga-Konju",
          "Suar-Handi",
          "Paplet-Seegadi"
        ],
        [
          "Korli kite-Thorake",
          "Raavas-Thatte",
          "Bhootayi",
          "Zori-Sura Meenu",
          "Bhed-Kuri",
          "Indian prawn",
          "Morrul Machli  - Nangu",
          "Pelagic fish - Olamanangu"
        ]
      ]
    };
  }
};
