<template>
  <table class='ige_table'>
    <tr class='ige_tr'>
      <th class='ige_th' style='width: 30%' rowspan='3'>Allergens</th>
      <th class='ige_th ige_header' rowspan='3'>* SAU/ml</th>
      <th class='ige_th ige_header'>SAU/ml</th>
      <th class='ige_th ige_header'>3.0 - 3.49</th>
      <th class='ige_th ige_header'>3.5 - 4.49</th>
      <th class='ige_th ige_header'>4.5 - 5.49</th>
      <th class='ige_th ige_header'>> 5.5</th>
    </tr>
    <tr class='ige_tr'>
      <th class='ige_th ige_header' rowspan='2'>Class</th>
      <th class='ige_th ige_header' style='background-color: green'>3</th>
      <th class='ige_th ige_header' style='background-color: yellow'>4</th>
      <th class='ige_th ige_header' style='background-color: orangered'>5</th>
      <th class='ige_th ige_header' style='background-color: red'>6</th>
    </tr>
    <tr class='ige_tr'>
      <th class='ige_th ige_header'>Low</th>
      <th class='ige_th ige_header'>Moderate</th>
      <th class='ige_th ige_header'>High</th>
      <th class='ige_th ige_header'>Very High</th>
    </tr>
    <tr class='ige_tr' v-for='(i, index) in items' :key='index'>
      <td class='ige_text'>{{ i.param }}</td>
      <td class='ige_text'>{{ i.val }}</td>
      <td class='ige_text'>{{ i.class }}</td>
      <td>
        <div :style='i.c3'>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </td>
      <td>
        <div :style='i.c4'>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </td>
      <td>
        <div :style='i.c5'>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </td>
      <td>
        <div :style='i.c6'>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </td>
      <!--      <td :style='i.c4'></td>-->
      <!--      <td :style='i.c5'></td>-->
      <!--      <td :style='i.c6'></td>-->
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    allergens: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    items() {
      if (this.allergens.length <= 0) return [];
      const arr = [];
      for (let i = 0; i < this.allergens.length; i++) {
        const td = {
          param: this.allergens[i].Name,
          val: this.allergens[i].Reading
        };
        td.class = this.getClass(td.val);
        td.c3 = td.class >= 3 ? this.getStyle(td.class) : '';
        td.c4 = td.class >= 4 ? this.getStyle(td.class) : '';
        td.c5 = td.class >= 5 ? this.getStyle(td.class) : '';
        td.c6 = td.class === 6 ? this.getStyle(td.class) : '';
        if (td.class >= 3 && td.class <= 6) arr.push(td);
      }
      return arr;
    }
  },
  methods: {
    getClass(val) {
      if (val >= 3 && val < 3.5) return 3;
      else if (val >= 3.5 && val < 4.5) return 4;
      else if (val >= 4.5 && val < 5.5) return 5;
      else if (val >= 5.5) return 6;
    },
    getStyle(val) {
      if (val === 3)
        return 'background-color: green; margin-top:8px;margin-bottom:8px;';
      else if (val === 4)
        return 'background-color: yellow; margin-top:8px;margin-bottom:8px;';
      else if (val === 5)
        return 'background-color: orangered; margin-top:8px;margin-bottom:8px;';
      else if (val === 6)
        return 'background-color: red; margin-top:8px;margin-bottom:8px;';
    }
  }
};
</script>

<style scoped></style>
