<template>
  <div>
    <table style='width: 85%;margin-left: 5%;margin-right: 5%'>
      <tr>
        <td style='width: 50%;text-align: start;'>
          Patient Name: {{ patientName }}
        </td>
        <td style='width: 50%;text-align: end'>
          Page: 1
        </td>
      </tr>
    </table>

    <br />
    <table
      style='width: 85%;margin-left: 5%;margin-right: 5%'
      class='ige_table'
    >
      <tbody>
        <tr class='ige_tr'>
          <th class='ige_th' style='width: 10%'>Code</th>
          <th class='ige_th' style='width: 40%'>Allergens</th>
          <th class='ige_th' style='width: 30%'>Local Name</th>
          <th class='ige_th' style='width: 10%'>SAU/ml</th>
          <th class='ige_th' style='width: 10%'>Class</th>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>GRASS POLLEN</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(2, 7, 0, 1, 7, 0)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>WEED POLLENS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(7, 8, 0, 4, 2, 3)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>TREE POLLENS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(3, 8, 3, 5, 5, 4)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>MOLDS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(6, 8, 4, 7, 0, 6)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <table style='width: 85%;margin-left: 5%;margin-right: 5%'>
      <tr>
        <td style='width: 50%;text-align: start;'>
          Patient Name: {{ patientName }}
        </td>
        <td style='width: 50%;text-align: end'>
          Page: 2
        </td>
      </tr>
    </table>
    <br />
    <table
      style='width: 85%;margin-left: 5%;margin-right: 5%'
      class='ige_table'
    >
      <tbody>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(1, 4, 6, 7, 3, 7)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>DUST</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(4, 8, 6, 8, 0, 7)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>HOUSE DUST MITE</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(1, 8, 7, 8, 3, 7)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>INSECTS</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(4, 8, 7, 9, 3, 8)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
        <tr class='ige_tr'>
          <td></td>
          <td class='ige_text ige_bold'>DANDER</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          class='ige_tr'
          v-for='i in getAllergyDetails(4, 8, 8, 9, 8, 8)'
          :key='i.code'
        >
          <td class='ige_text'>{{ i.code }}</td>
          <td class='ige_text'>{{ i.allergen }}</td>
          <td class='ige_text'>{{ i.local }}</td>
          <td class='ige_text'>{{ i.sau }}</td>
          <td class='ige_text ige_bold'>{{ i.cl }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { airAllergyMixin } from '../../api/airAlergyMixin';
import { airAllergyLocalMixin } from '../../api/airAllergyLocalMixin';
export default {
  mixins: [airAllergyMixin, airAllergyLocalMixin],
  props: {
    patientName: {
      type: String,
      default: ''
    },
    testValues: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allergyCodes: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    getAllergyDetails(
      rowStart,
      rowEnd,
      colStart,
      colEnd,
      breakRowCount,
      breakColCount
    ) {
      if (this.testValues.length < 1) return [];
      const pollens = [];
      for (let j = colStart; j < colEnd; j++) {
        for (let i = rowStart; i < rowEnd; i++) {
          const val = {
            code: this.allergyCodes[i] + (j + 1),
            allergen: this.airAllergens[j][i],
            local: this.airAllergyLocals[j][i],
            sau: 0,
            cl: ''
          };
          if (this.testValues[i][j]) {
            const sau = this.getSau(
              this.testValues[0][0],
              this.testValues[i][j]
            );
            val.sau = sau.sau;
            val.cl = sau.cl;
          }
          pollens.push(val);
          if (i === breakRowCount && j === breakColCount) break;
        }
        rowStart = 0;
      }
      return pollens;
    },
    getSau(control, value) {
      const sau = (value / control).toFixed(2);
      let cl = '';
      if (sau >= 3 && sau < 3.5) cl = '3';
      else if (sau >= 3.5 && sau < 4.5) cl = '4';
      else if (sau >= 4.5 && sau < 5.5) cl = '5';
      else if (sau >= 5.5) cl = '6';
      return {
        sau: sau,
        cl: cl
      };
    }
  }
};
</script>

<style scoped></style>
