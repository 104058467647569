export const vegAllergyMixin = {
  data() {
    return {
      vegAllergens: [
        [
          "",
          "",
          "Ash guord",
          "Bay leaf",
          "Beet root",
          "Biltom",
          "Bitter guord",
          "Bottle guord"
        ],
        [
          "Brinjal",
          "Cabbage",
          "Capsicum",
          "Carrot",
          "Cauliflower",
          "Coconut",
          "Coriander leaf",
          "Cucumber"
        ],
        [
          "Curry leaf",
          "Dill leaf",
          "Drumstick",
          "Fenugreek",
          "Knol khol",
          "Lady's finger",
          "Lettuce",
          "Maize corn"
        ],
        [
          "Mint",
          "Mushroom",
          "Onion",
          "Potato",
          "Pumpkin",
          "Radish",
          "Ridge guord",
          "Snake guord"
        ],
        [
          "Spinach",
          "Sweet potato",
          "Thindora",
          "Tomato",
          "Turnip",
          "Black pepper",
          "Chilli",
          "China salt / tasting salt"
        ],
        [
          "Coriander seeds",
          "Cumin seeds",
          "Food colors (orange & green)",
          "Garlic",
          "Ginger",
          "Saffron",
          "Sesame seeds",
          "Turmeric"
        ],
        [
          "Bengal gram",
          "Chick pea / garbenzo beans",
          "Fried gram",
          "Green gram",
          "Horse gram",
          "Masoor dal",
          "Split black beans / white lentils",
          "Yellow lentils / pigeon pea"
        ],
        [
          "Coffee",
          "Tea",
          "Black eye beans",
          "Broad beans",
          "Cluster beans",
          "Field beans",
          "French beans",
          "Green pea"
        ],
        [
          "Kidney beans",
          "Soyabean",
          "String beans",
          "Apple",
          "Banana (spotted, yellakki)",
          "Cherry",
          "Chiku",
          "Custard apple"
        ],
        [
          "Gooseberry",
          "Grape mix (black & white)",
          "Guava",
          "Jackfruit",
          "Lemon",
          "Lychee",
          "Mango",
          "Meshmelon"
        ],
        [
          "Olives (black & green)",
          "Orange",
          "Papaya",
          "Pineapple",
          "Pomegranate",
          "Sugarcane",
          "Sweet lime",
          "Tamarind"
        ],
        [
          "Watermelon",
          "Finger millet",
          "Flattened rice / beaten rice",
          "Jowar",
          "Maida",
          "Oats",
          "Rice",
          "Wheat"
        ],
        [
          "Almonds",
          "Apricot",
          "Cashew nuts",
          "Dates",
          "Groundnut / peanuts",
          "Hazelnut",
          "Pista",
          "Raisins"
        ],
        [
          "Walnut",
          "Baker's yeast",
          "Butter",
          "Casien / whey protein",
          "Cheese",
          "Chocolate",
          "Curd",
          "Milk"
        ],
        [
          "Paneer",
          "Pure ghee",
          "Flax seeds",
          "Mustard",
          "Poppy seeds",
          "Chilli flakes",
          "Jalapeno",
          "Oregano"
        ]
      ]
    };
  }
};
